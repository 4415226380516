export default {
  init() {
    // JavaScript to be fired on all pages

    // sliders
    $('.header_slider').each(function(){
      $( this ).slick({
        lazyLoad: 'progressive',
        autoplay: true,
        pauseOnHover: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        speed: 3500,
        fade: true,
        arrows: true
      });
    });

     // sliders
     $('.collage_slider').each(function(){
      $( this ).slick({
        lazyLoad: 'progressive',
        autoplay: false,
        pauseOnHover: true,
        dots: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        speed: 300,
        fade: false,
        arrows: true,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
        ],
      });
    });

     // sliders
     $('.item_slider').each(function(){
      $( this ).slick({
        lazyLoad: 'progressive',
        autoplay: false,
        pauseOnHover: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        speed: 300,
        fade: false,
        arrows: false,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
        ],
      });
    });
  
    $('.klant_slider').each(function(){
      $( this ).slick({
        lazyLoad: 'ondemand',
        speed: 9000,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: 'linear',

        pauseOnHover: true,
        dots: false,

        slidesToShow: 2,
        slidesToScroll: 1,
     
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
            },
          },
        ],
      });
    });
      

  },
  finalize() {
    // header change on scroll
    $(window).scroll(function(){
      var sticky = $('.logo_reveal'),
          scroll = $(window).scrollTop();

      if (scroll >= 32){
        sticky.addClass('toggle-logo');
      }else{
        sticky.removeClass('toggle-logo');
      }
    });

    // menu toggle mobile 
    $('.icon_burger').on( 'click', function() {
      $('.top-menu').toggleClass('top-animate');
      $('.mid-menu').toggleClass('mid-animate');
      $('.bottom-menu').toggleClass('bottom-animate');

      $('.nav-primary').toggleClass('active-menu');
      
      $('body').toggleClass('noscroll');
    });

    $('.menu-item-has-children .svg-inline--fa').on( 'click', function() {
      
      $( this ).parent().find( '.sub-menu' ).toggleClass( 'active-sub-menu' );

    });

  },
};

AOS.init();

// Show the icon immediatly when the script is called.
/*
$('#loading').show();

// show the icon when the page is unloaded
$(window).on('beforeunload', function(event) {
  $('#loading').show();
});

// hide the icon when the page is fully loaded
$(window).on('load', function(event) {
  $('#loading').hide();
});*/